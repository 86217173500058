/* eslint-disable react/no-array-index-key */
import {
  Stack,
  IconButton,
  Link,
  Box,
  Text,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';

import { socialLinks } from '@/means/data';

const iconProps = {
  variant: 'ghost',
  size: 'lg',
  isRound: true,
};

export function Footer(): JSX.Element {
  return (
    <Stack
      isInline
      spacing={[1, 2]}
      p={4}
      justifyContent="space-between"
      alignItems="center"
      w={['100%', '90%', '90%']}
      maxW="container.lg"
      mx="auto"
    >
      <Flex
        flexDirection={['column', 'column', 'row']}
        flexFlow={['column-reverse', 'column-reverse']}
        justifyContent={['center', 'space-between']}
        alignItems="center"
        w="100%"
      >
        <Text
          textAlign="center"
          fontSize="sm"
          color={useColorModeValue('gray.500', 'gray.200')}
        >
          © {new Date().getFullYear()} Gabriel Hijazi{' '}
        </Text>

        <Box textAlign="center">
          {socialLinks.author.accounts.map((sc, index) => (
            <IconButton
              key={index}
              as={Link}
              isExternal
              aria-label={sc.name}
              href={sc.url}
              colorScheme={sc.type}
              icon={sc.icon}
              {...iconProps}
            />
          ))}
        </Box>
      </Flex>
    </Stack>
  );
}
