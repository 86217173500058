import { Footer } from '@/components/common/Footer';
import { Header } from '@/components/common/Header';

interface LayoutProps {
  children: React.ReactNode;
}

export function Layout({ children }: LayoutProps): JSX.Element {
  return (
    <>
      <Header />
      <main style={{ flexGrow: '1' }}>{children}</main>
      <Footer />
    </>
  );
}
