import Head from 'next/head';

import styles from '@/styles/Head.module.css';

export default function SEO(): JSX.Element {
  return (
    <div className={styles.container}>
      <Head>
        <title>Gabriel Hijazi</title>
        <meta property="og:title" content="Gabriel Hijazi | Hello World!" />
        <meta name="description" content="Gabriel Hijazi | Hello World!" />
        <link
          rel="icon"
          href="https://avatars.githubusercontent.com/u/46979459?v=4"
        />
      </Head>
    </div>
  );
}
