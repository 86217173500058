/* eslint-disable react/jsx-no-useless-fragment */
import '@/styles/global.css';

import { ChakraProvider } from '@chakra-ui/react';
import { AppProps } from 'next/app';
import { useEffect, useState } from 'react';

import SEO from '@/components/common/Head';
import { Layout } from '@/layout/Layout';
import { theme } from '@/styles/theme';

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  const [showing, setShowing] = useState(false);

  useEffect(() => {
    setShowing(true);
  }, []);

  if (!showing) {
    return <></>;
  }

  return (
    <>
      <SEO />
      <ChakraProvider theme={theme}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ChakraProvider>
    </>
  );
}
