import { FaGithub, FaLinkedin, FaReact, FaHtml5, FaNode } from 'react-icons/fa';
import { GoLogoGithub } from 'react-icons/go';
import { GrGolang } from 'react-icons/gr';
import {
  SiCsswizardry,
  SiGmail,
  SiKotlin,
  SiNestjs,
  SiMysql,
  SiPostgresql,
  SiMongodb,
  SiTypescript,
  SiJavascript,
} from 'react-icons/si';
import { TbBrandReactNative } from 'react-icons/tb';

export const menuLinks = [
  { name: 'Início', route: '/' },
  { name: 'Sobre', route: '/about' },
  { name: 'Github', route: '/github' },
  { name: 'Blog', route: '/blog' },
];

export const socialLinks = {
  copyright: `Copyright © ${new Date().getFullYear()} Gabriel Hijazi. Todos os direitos reservados`,
  author: {
    name: 'Gabriel Hijazi',
    accounts: [
      {
        url: 'https://github.com/devhijazi',
        icon: <FaGithub />,
        name: 'Github',
        type: 'gray',
      },
      {
        url: 'https://www.linkedin.com/in/gabriel-hijazi-3a5772183/',
        icon: <FaLinkedin />,
        name: 'Linkedin',
        type: 'linkedin',
      },
      {
        url: 'mailto:hijazi.root@gmail.com',
        icon: <SiGmail />,
        name: 'Gmail',
        type: 'red',
      },
    ],
  },
};

export const skill = [
  {
    name: 'HTML',
    icon: <FaHtml5 fontSize="20px" />,
    url: '',
  },
  {
    name: 'CSS',
    icon: <SiCsswizardry fontSize="20px" />,
    url: '',
  },
  {
    name: 'TypeScript',
    icon: <SiJavascript fontSize="20px" />,
    url: '',
  },
  {
    name: 'JavaScript',
    icon: <SiTypescript fontSize="20px" />,
    url: '',
  },
  {
    name: 'GO',
    icon: <GrGolang fontSize="20px" />,
    url: '',
  },
  {
    name: 'React',
    icon: <FaReact fontSize="20px" />,
    url: 'https://reactjs.org/',
  },
  {
    name: 'React Native',
    icon: <TbBrandReactNative fontSize="20px" />,
    url: 'https://reactnative.dev/',
  },
  {
    name: 'Kotlin',
    icon: <SiKotlin fontSize="20px" />,
    url: 'https://kotlinlang.org/',
  },
  {
    name: 'Node',
    icon: <FaNode fontSize="20px" />,
    url: '',
  },
  {
    name: 'Nest.js',
    icon: <SiNestjs fontSize="20px" />,
    url: '',
  },
  {
    name: 'MySQL',
    icon: <SiMysql fontSize="20px" />,
    url: '',
  },
  {
    name: 'PostgreSQL',
    icon: <SiPostgresql fontSize="20px" />,
    url: '',
  },
  {
    name: 'MongoDB',
    icon: <SiMongodb fontSize="20px" />,
    url: '',
  },
  {
    name: 'Github',
    icon: <GoLogoGithub fontSize="20px" />,
    url: '',
  },
];

export const projects = [
  {
    name: 'Urbano Norte',
    imageUrl: 'https://urbanonorte.app/_next/static/media/logo.0b964f4f.png',
    alt: 'urbanonorte',
    summary: 'Plataforma de mobilidade urbana',
  },
  {
    name: 'UrbanoLog',
    imageUrl: 'https://urbanonorte.app/_next/static/media/logo.0b964f4f.png',
    alt: 'urbanolog',
    summary: 'Plataforma de viagens entre cidades e estados',
  },
  {
    name: 'Marinetes Clean House',
    imageUrl:
      'https://play-lh.googleusercontent.com/D2CJZizkgDN1RVKbQ9OCuvNp6eYfGmNTPdLABwTj6iyy0LJnubf8DmTcZaIoBfMfEGE=w240-h480-rw',
    alt: 'Marinetes',
    summary: 'Sua casa sempre limpa',
  },
];
