/* eslint-disable react-hooks/rules-of-hooks */
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Flex,
  Stack,
  HStack,
  IconButton,
  Link as ChakraLink,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { menuLinks } from '@/means/data';

import { ThemeSwitcher } from '../ui/ThemeSwitcher';

export function Header(): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const router = useRouter();
  const { asPath } = router;

  const navLinks = (
    <>
      {menuLinks.map(link => (
        <NextLink href={link.route} key={link.name} passHref>
          <ChakraLink
            href={link.route}
            px={2}
            py={1}
            _hover={{
              textDecoration: 'none',
              borderBottom: '2px solid',
              borderColor: useColorModeValue('cyan.500', 'green.200'),
            }}
            color={
              link.route === asPath
                ? useColorModeValue('purple.500', 'purple.900')
                : 'purple.500'
            }
            onClick={isOpen ? onClose : onOpen}
          >
            {link.name}
          </ChakraLink>
        </NextLink>
      ))}
    </>
  );

  return (
    <Box bg={useColorModeValue('white', 'gray.900')} px={4} boxShadow="lg">
      <Flex
        h={16}
        alignItems="center"
        justifyContent="space-between"
        w={{ base: '95%', md: '95%', sm: '95%' }}
        maxW="container.lg"
        mx="auto"
      >
        <IconButton
          size="md"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label="Open Menu"
          display={['inherit', 'inherit', 'none']}
          onClick={isOpen ? onClose : onOpen}
        />

        <HStack as="nav" spacing="4" display={{ base: 'none', md: 'flex' }}>
          <Avatar
            as={ChakraLink}
            href="/"
            size="sm"
            src="https://avatars.githubusercontent.com/u/46979459?v=4"
          />
          <HStack as="nav" spacing="4" display={{ base: 'none', md: 'flex' }}>
            {navLinks}
          </HStack>
        </HStack>
        <Flex alignItems="center">
          <ThemeSwitcher justifySelf="flex-end" />
        </Flex>
      </Flex>
      {isOpen && (
        <Box
          pb={4}
          w={['100%', '100%', '80%']}
          maxW="container.lg"
          display={['inherit', 'inherit', 'none']}
        >
          <Stack as="nav" spacing={4}>
            {navLinks}
          </Stack>
        </Box>
      )}
    </Box>
  );
}
